<template>
    <div class="container">
        <div class="instruction">
            <h2>{{ $t('Инструкция') }}</h2>
            <p>{{ $t('Шаг 1. Вы находитесь в системе обучения финансовым расследованиям Графус') }}</p>
            <p>{{ $t('Шаг 2. Зайдите в свой профиль и выберите вкладку ‘Мои кейсы‘') }}</p>
            <p>{{ $t('Шаг 3. Прочитайте задание и начните кейс. Так же можно перейти в прикрепленный администратором телеграмм чат') }}</p>
            <p>{{ $t('Шаг 4. После старта кейса будет создана аналитическая сессия Расследование') }}</p>
            <p>{{ $t('Шаг 5. Во вкладке расследование вам представлено 3 аналитических инстурмента') }}</p>
            <ul>
                <li>{{ $t('поисковая строка') }}</li>
                <li>{{ $t('конструктор схем') }}</li>
                <li>{{ $t('визуализатор схем') }}</li>
            </ul>
            <p>{{ $t('Шаг 6. Используя аналитические инструменты добавьте в избранное сущности, подходящие под ответ кейса') }}</p>
            <p>{{ $t('Шаг 7. Сформируйте ответ и завершите кейс. Ваш результат будет отображен на табло администратора') }}</p>
        </div>
    </div>
</template>

<script>
    // import ExampleService from '../services/example.service';

    export default {
        name: 'User',
        data() {
            return {
                content: ''
            };
        },
        mounted() {
            // ExampleService.getUserBoard().then(
            //     response => {
            //         this.content = response.data;
            //     },
            //     error => {
            //         this.content =
            //             (error.response && error.response.data) ||
            //             error.message ||
            //             error.toString();
            //     }
            // );
        }
    };
</script>

<style>
    .instruction {
        background: rgba(255, 255, 255, 0.95);
        padding-top: 10px;
    }
    p {
        text-align: left;
    }


</style>